import React from 'react';
import loadingGif from '../img/loading.gif';

const GenerationAnimation = () => {
return (
    <div>
        <img src={loadingGif} alt="Loading..." style={{ width: '20px', height: '20px' }} />
        {/*<p className='generationText'>Генерация...</p>*/}
    </div>
)
};

export default GenerationAnimation;
